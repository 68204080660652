
import {namespace} from 'vuex-class';
import {Component, Prop} from 'vue-property-decorator';
import {mixins} from 'vue-class-component';
import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixins';
import {validationMixin} from 'vuelidate';
import {required} from 'vuelidate/lib/validators';
import {APPLICATION_STORE_NAME} from "@/store/application.store";
import Advertisement from "@/models/Advertisement.model";
import AdvertisementRepository from "@/api/repositories/AdvertisementRepository";
import {DateTime} from "luxon";
import {AdvertisementSizes} from "@/misc/AdvertisementSizes";

const ApplicationStore = namespace(APPLICATION_STORE_NAME);

interface CreateAdvertisementStep {
  validations: string[],
  valid: boolean,
  callback?: any
}

@Component({
  components: {
    CustomStepperComponent: () => import(
      /* webpackChunkName: "CustomStepperComponent" */
      '@/components/Misc/CustomStepper.component.vue'
    ),
  },
  mixins: [validationMixin],
  validations: {
    url: { required },
    format: { required },
    customerName: {required},
    file: {required}
  }
})
export default class CreateAdvertisementComponent extends mixins(ErrorMessageHandlerMixin) {
  @Prop()
  public advertisement?: Advertisement;

  private customerName = '';
  private url = '';
  private format = '';
  private active = true;

  /**
   * picked month
   * @private
   */
  private activeFrom: string = "";

  /**
   * picked month
   * @private
   */
  private activeTo: string = "";

  private showFromPicker = false;

  private showToPicker = false;

  /**
   * list of accepted types for image upload
   * @private
   */
  private get acceptedTypes(): string {
    return process.env.VUE_APP_ACCEPTED_TYPES!;
  }

  /**
   * formats the date that was picked
   * @private
   */
  private get activeFromFormattedDate(): string {
    const date = this.activeFrom;
    if(!date) return '';

    const dateTime = DateTime.fromISO(date?.toString());
    return this.$formatDate(dateTime);
  }

  private set activeFromFormattedDate(value: string) {
    this.activeFrom = '';
  }

  /**
   * formats the date that was picked
   * @private
   */
  private get activeToFormattedDate(): string {
    const date = this.activeTo;
    if(!date) return '';

    const dateTime = DateTime.fromISO(date?.toString());
    return this.$formatDate(dateTime);
  }

  private set activeToFormattedDate(value: string) {
    this.activeTo = '';
  }

  private onFromActiveChanged(date: string) {
    this.showFromPicker = false;
    const fDate = DateTime.fromISO(date);

    // Save Data to it's v-model
    (this.$refs.dateFromPickerMenu as any).save(fDate);
  }

  private onToActiveChanged(date: string) {
    this.showToPicker = false;

    const fDate = DateTime.fromISO(date);

    // Save Data to it's v-model
    (this.$refs.dateToPickerMenu as any).save(fDate);
  }

  /**
   * copy of the advertisement, to allow to change the advertisement without modifying the original
   * @private
   */
  private advertisementCopy!: Advertisement;

  /**
   * flag that indicates if the component is loading
   * @private
   */
  private isLoading = false;

  private file: File | null = null;

  private sizeOptions = AdvertisementSizes;

  private get now() {
    return DateTime.now().toString();
  }

  /**
   * inits the news object that is used to store and pass the entered inputs
   */
  private async created() {
    this.advertisementCopy = this.advertisement ?? Advertisement.parseFromObject({active: true});
    this.customerName = this.advertisementCopy.customerName ?? '';
    this.url = this.advertisementCopy.url ?? '';
    this.format = this.advertisementCopy.size ?? '';
    this.active = this.advertisementCopy.active;
  }

  private mounted() {
    if(this.advertisementCopy.activeFrom) this.activeFrom = this.advertisementCopy.activeFrom;
    if(this.advertisementCopy.activeTo) this.activeTo = this.advertisementCopy.activeTo;
  }

  private triggerAllValidations =
    {validations: ['url', 'format', 'customerName'], valid: true};

  private additionalValidations =
    {validations: ['file'], valid: true};

  /**
   * is called when the save button is pressed, checks if a message was entered and edits the original news
   */
  private async saveData() {
    if(!await this.validateStep(this.triggerAllValidations) ||
        (!this.advertisement && !await this.validateStep(this.additionalValidations))) {
      return;
    }

    if(this.file!.size > parseInt(process.env.VUE_APP_MAX_FILE_SIZE!)) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.FILE_SIZE_TOO_BIG');
      return;
    }

    try {
      this.isLoading = true;
      let payload: {ad: Advertisement, file: File} = {
        ad: Advertisement.parseFromObject({url: this.url, size: this.format, customerName: this.customerName,
          active: this.active, id: this.advertisementCopy.id}),
        file: this.file!
      };

      if(this.activeFrom) {
        payload = {...payload,
          ad: Advertisement.parseFromObject({...payload.ad, activeFrom: new Date(this.activeFrom).toISOString()})
        };
      }

      if(this.activeTo) {
        payload = {...payload,
          ad: Advertisement.parseFromObject({...payload.ad, activeTo: new Date(this.activeTo).toISOString()})
        };
      }

      if(this.advertisement) {
        // tries to update the advertisement
        await AdvertisementRepository.updateAdvertisement(payload);
        this.$notifySuccessSimplified('GENERAL.NOTIFICATIONS.ADVERTISEMENT_EDIT_SUCCESS');
      } else {
        // tries to create an advertisement
        await AdvertisementRepository.createAdvertisement(payload);
        this.$notifySuccessSimplified('GENERAL.NOTIFICATIONS.ADVERTISEMENT_CREATE_SUCCESS');
      }
      this.dismiss(true);
    } catch(_) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.ADVERTISEMENT_CREATE_EDIT_ERROR');
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * validates a certain step by checking each validation parameter
   * @param step
   * @private
   */
  private async validateStep(step: any): Promise<boolean> {
    let isValid = true;
    for (let s of step.validations) {
      let valid = await this.checkForm(s, step);
      if (!valid) isValid = false;
    }
    step.valid = isValid;
    return isValid;
  }

  /**
   * checks a specific type
   * @param type
   * @param step
   * @private
   */
  private async checkForm(type: string, step: any): Promise<boolean> {
    const inputValid = this.triggerValidation(type);
    step!.valid = inputValid;
    return inputValid;
  }

  /**
   * closes the modal, sends flag in event that indicate if something has updated and the news should reload
   * @param reload
   * @private
   */
  private dismiss(reload: boolean = false) {
    this.$v.$reset();
    this.$emit('closeDialog', reload);
  }
}
